@-ms-viewport {
  width: device-width; }

@media (min-width: 992px) {
  .coverage_block .row {
    flex-wrap: nowrap;
    align-items: center; } }

.coverage_title strong {
  display: inline-block;
  padding: 7px 12px;
  background-color: #21428C;
  color: white;
  line-height: 1.1; }

@media (max-width: 991.98px) {
  .coverage_title br {
    display: none; } }

@media (min-width: 992px) {
  .coverage_title {
    width: auto;
    white-space: nowrap; }
    .coverage_title strong {
      transform: skew(-15deg); } }

.coverage_list {
  font-size: 90%;
  line-height: 1.3; }
  @media (min-width: 992px) {
    .coverage_list {
      width: 100%; } }

.info_block {
  padding: 30px 15px;
  line-height: 1.5786;
  text-align: center;
  background-color: #12254e;
  color: white; }
  .info_block .phone {
    font-size: 130%; }
  @media (min-width: 768px) {
    .info_block {
      text-align: left; }
      .info_block .row {
        flex-wrap: nowrap;
        justify-content: space-between; }
      .info_block .wrap {
        width: auto; } }

@media (min-width: 768px) {
  .info_copyright {
    text-align: right; } }

.info-list {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

address {
  margin: 0 0 15px;
  font-style: normal;
  line-height: 1; }
  address .street {
    display: block; }

.hidden {
  display: none !important; }

.invisible {
  opacity: 0 !important; }

@media (max-width: 575.98px) {
  .hidden_xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .hidden_sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .hidden_md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hidden_lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden_xl {
    display: none !important; } }

[class*="visible_"] {
  display: none !important; }

@media (max-width: 575.98px) {
  .visible_xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .visible_sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .visible_md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .visible_lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible_xl {
    display: block !important; } }

.fs100 {
  font-size: 100% !important; }

.fs115 {
  font-size: 115% !important; }

.fs125 {
  font-size: 125% !important; }

.txt_center {
  text-align: center; }

.lh1 {
  line-height: 1 !important; }

.lh125 {
  line-height: 1.25 !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt5 {
  padding-top: 5px !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt25 {
  padding-top: 25px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.color-text {
  color: #0e1b39 !important; }

.color-red {
  color: #dd5329 !important; }

.color-primary {
  color: #21428C !important; }

.color-secondary {
  color: #e43f1e !important; }

.color-highlight {
  color: #1791f5 !important; }

.color-accent {
  color: #FBAE06 !important; }

.color-review {
  color: #ff9900 !important; }

.color-success {
  color: #59b823 !important; }

.color-danger {
  color: #dd5329 !important; }

.color-warning {
  color: #edc63b !important; }

.color-info {
  color: #6610f2 !important; }

.color-link {
  color: #0073df !important; }

/* .color- { color: $color- !important; }
.color- { color: $color- !important; } */
.bg_white {
  background-color: white; }

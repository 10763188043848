.coverage {

	&_block {
		@include media-breakpoint-up(lg) {
			.row { 
				flex-wrap: nowrap; 
				align-items: center;
			}
		}
	}

	&_title {
		strong {
			display: inline-block;
			padding: 7px 12px;
			background-color: $brand-primary;
			color: white;
			line-height: 1.1;
		}
		@include media-breakpoint-down(md) {
			br { display: none; }
		}
		@include media-breakpoint-up(lg) {
			width: auto;
			white-space: nowrap;
			strong { transform: skew(-15deg); }
		}
	}

	&_list {
		font-size: 90%;
		line-height: 1.3;
		@include media-breakpoint-up(lg) {
			width: 100%;
		}
	}

}

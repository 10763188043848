// =====================================================
// 1. GRID
// =====================================================
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px);

$grid-columns: 16;
$grid-gutter-width: 30px;
$grid-row-columns: 8;
 
$gutter: 30px;

$global-mb: 15px;

$nav-bp-down: sm;
$nav-bp: md;

// ===========================================
// 3. TYPOGRAPHY
// ===========================================

$font-family-sans-serif: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif: 'Times New Roman', Georgia, Times, serif; 
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;

$font-family-headings: $font-family-sans-serif;
$font-family-body: $font-family-sans-serif;

$font-size-base: 16;
$base-line-height: 1.5786;

$body-weight-normal: 400;
$body-weight-bold: 600;
$body-weight-bolder: 800;

$heading-weight-normal: 400;
$heading-weight-bold: 600;
$heading-weight-bolder: 800;


// =====================================================
// 4. COLORS
// Midnight Blue: 	#372772;
// Yale Blue: 		#1b4079; 
// YinMn BLue: 		#26547C;
// =====================================================
$blue:    	#0073df !default;
$indigo:  	#6610f2 !default;
$purple:  	#6f42c1 !default;
$pink:    	#e83e8c !default;
$red:     	#dd5329 !default;
$orange:  	#ff9900 !default;
$yellow:  	#edc63b !default; // #ffc107 !default;
$green:   	#59b823 !default;
$teal:    	#20c997 !default; 
$cyan:    	#15E0E0 !default;
$maroon: 	#8b1b2c !default;
$gold: 		#786a4b !default;
$white: 	#f5f5f5 !default;
$black: 	#090C02 !default;

// DARK BLUE: #000F4A;

$brand-primary: 		#21428C; // #7DCFD8; // #56AAB3; // Moonstone
$brand-secondary: 		#e43f1e; // #C4433A; //#CE9550; // Butterscotch
$brand-highlight:   	#1791f5; // #537E3A; // Chestnut
$brand-accent:   		#FBAE06; // Fern Green

$color-review: 			$orange; 
$color-success: 		$green; 
$color-danger: 			$red; 
$color-warning: 		$yellow;
$color-info: 			$indigo;
$color-link:			$blue;

$color-facebook:		#3a8bf4;
$color-google:			#ed5f53;
$color-trip-advisor:	#26b782;
$color-yelp: 			#da4444;
$color-home-advisor:	#0c0959;
$color-angi:  			#ff6153;

// COLOR MODIFIES
$color-lighten: 15% !default;
$color-darken: 15% !default;
$color-opac: 0.6 !default;
$color-sat: 15% !default;
$color-desat: 15% !default;


.info {

	&_block {
		padding: 30px 15px;
		line-height: $base-line-height;
		text-align: center;
		background-color: $brand-primary-dark;
		color: white;

		.phone { font-size: 130%; }

		@include media-breakpoint-up(md) {
			text-align: left;
			.row { 
				flex-wrap: nowrap; 
				justify-content: space-between;
			}
			.wrap { width: auto; }
		}

	}

	&_copyright {
		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}

	&-list {
		@include list-unstyled;
	}

}
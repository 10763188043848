$theme: (
	primary: (
		base: $brand-primary,
		light: lighten($brand-primary, $color-lighten),
		dark: darken($brand-primary, $color-darken),
		opac: transparentize($brand-primary, $color-opac),
		sat: saturate($brand-primary, $color-sat),
		desat: desaturate($brand-primary, $color-desat)
	),
	secondary: (
		base: $brand-secondary,
		light: lighten($brand-secondary, $color-lighten),
		dark: darken($brand-secondary, $color-darken),
		opac: transparentize($brand-secondary, $color-opac),
		sat: saturate($brand-secondary, $color-sat),
		desat: desaturate($brand-secondary, $color-desat)
	),
	highlight: (
		base: $brand-highlight,
		light: lighten($brand-highlight, $color-lighten),
		dark: darken($brand-highlight, $color-darken), 
		opac: transparentize($brand-highlight, $color-opac),
		sat: saturate($brand-highlight, $color-sat),
		desat: desaturate($brand-highlight, $color-desat)
	),
	accent: (
		base: $brand-accent,
		light: lighten($brand-accent, $color-lighten),
		dark: darken($brand-accent, $color-darken),
		opac: transparentize($brand-accent, $color-opac),
		sat: saturate($brand-accent, $color-sat),
		desat: desaturate($brand-accent, $color-desat)
	),
	review: (
		base: $color-review,
		light: lighten($color-review, $color-lighten),
		dark: darken($color-review, $color-darken),
		opac: transparentize($color-review, $color-opac),
		sat: saturate($color-review, $color-sat),
		desat: desaturate($color-review, $color-desat)
	),
	success: (
		base: $color-success,
		light: lighten($color-success, $color-lighten),
		dark: darken($color-success, $color-darken),
		opac: transparentize($color-success, $color-opac),
		sat: saturate($color-success, $color-sat),
		desat: desaturate($color-success, $color-desat)
	),
	danger: (
		base: $color-danger,
		light: lighten($color-danger, $color-lighten),
		dark: darken($color-danger, $color-darken),
		opac: transparentize($color-danger, $color-opac),
		sat: saturate($color-danger, $color-sat),
		desat: desaturate($color-danger, $color-desat)
	),
	link: (
		base: $color-link,
		light: lighten($color-link, $color-lighten),
		dark: darken($color-link, $color-darken),
		opac: transparentize($color-link, $color-opac),
		sat: saturate($color-link, $color-sat),
		desat: desaturate($color-link, $color-desat)
	)	
);

$brand-primary-dark: theme(primary, dark);
$brand-primary-light: theme(primary, light);

$brand-secondary-dark: theme(secondary, dark);
$brand-secondary-light: theme(secondary, light);

$brand-highlight-dark: theme(highlight, dark);
$brand-highlight-light: theme(highlight, light);

$brand-accent-dark: theme(accent, dark);
$brand-accent-light: theme(accent, light);

$color-review-dark: theme(review, dark);
$color-review-light: theme(review, light);

$color-danger-dark: theme(danger, dark);
$color-danger-light: theme(danger, light);

$color-success-dark: theme(success, dark);
$color-success-light: theme(success, light);


// SITE COLORS

$body-bg:				$white;
$color-text:			darken($brand-primary, 20%);
$color-hover:			lighten($color-link, $color-lighten);
$color-phone:			$brand-primary;


// SITE NAVIGATION
$nav_bp_down: 			"sm";
$nav_bp_up: 			"md";

$toggle_bg_link: 		$brand-primary;
$toggle_bg_hover:		$brand-highlight;

$toggle_svg_link:		white;
$toggle_svg_hover:		white;

$nav-title: 			$brand-primary;

$nav-color-link: 		white;
$nav-color-hover:		white;
$nav-color-active: 		$brand-primary;

$nav-bg-link: 			$brand-primary;
$nav-bg-hover: 			$brand-highlight;
$nav-bg-active: 		transparent;

$dd-bg-bp-down:			rgba(white,0.4); //$body-bg;
$dd-bg-bp-up: 			$body-bg; //$white;

$dd-link-color:			$brand-primary; //$nav-color-link;
$dd-link-hover: 		$brand-highlight;
$dd-link-active: 		$brand-secondary;

$dd-bg-link: 			transparent; //$nav-bg-link;
$dd-bg-hover: 			transparent;
$dd-bg-active: 			transparent;
